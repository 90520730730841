import axios from 'axios'
import store from '@/store'

function showAxiosLoading(showYn: boolean) {
  /* alert(showYn) */
  const loadingCompo = document.getElementById('axiosShadow')
  // eslint-disable-next-line no-debugger
  // debugger
  if (showYn) {
    /*  if (loadingCompo.style.display === 'flex') */
    if (loadingCompo) loadingCompo.style.display = 'flex'
    setTimeout(() => {
      if (loadingCompo) loadingCompo.style.display = 'none'
    }, 3000)
  } else {
    if (loadingCompo) loadingCompo.style.display = 'none'
  }
}
async function uploadFile(
  fileList: any[],
  fileName: string,
  isConverting?: boolean
) {
  showAxiosLoading(true)
  console.log(fileList)
  const apiUrl = isConverting
    ? 'https://www.josa1.kr:9443/josa1/jo.saveBogoFile'
    : 'https://www.mzoin.com/josaFile/jo.uploadFile'
  const form = new FormData()
  let newFile: any = {}
  for (let i = 0; i < fileList.length; i++) {
    if (!fileList[i]) return
    // Here we create unique key 'files[i]' in our response dictBase64.decode(data)
    // thisthis.uploadFileList[i].previewImgUrl = Base64.decode(thisthis.uploadFileList[i].previewImgUrl.replaceAll('data:image/png;base64,', ''))
    console.log(fileList[i])
    const oldFile = fileList[i].file
    console.log(oldFile)
    if (fileName !== null && fileName !== undefined) {
      let replaceName = fileName
      if (typeof replaceName === 'string') {
        replaceName = replaceName.replaceAll('[', '(')
        if (typeof replaceName === 'string') {
          replaceName = replaceName.replaceAll(']', ')')
        }
      }
      newFile = new File([oldFile], replaceName, { type: oldFile.type })
    } else {
      let replaceName = oldFile.name
      if (typeof replaceName === 'string') {
        replaceName = replaceName.replaceAll('[', '(')
        if (typeof replaceName === 'string') {
          replaceName = replaceName.replaceAll(']', ')')
        }
        if (typeof replaceName === 'string') {
          replaceName = replaceName.replaceAll(' ', '_')
        }
      }
      newFile = new File([oldFile], replaceName.normalize('NFC'), {
        type: oldFile.type
      })
    }
    form.append('files[' + i + ']', newFile)
  }
  const result = await axios.post(apiUrl, form, {
    headers: {
      'Content-Type': 'multipart/form-data; charset: UTF-8;'
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentage = (progressEvent.loaded * 100) / progressEvent.total
        // progressEvent.loaded는 현재까지 로드 된 수치를 나타내고
        // progressEvent.total은 전체 수치를 나타낸다.
        const percentCompleted = Math.round(percentage)
        const fileName = newFile.name
        store.dispatch('JO_JOSA/AC_UPLOAD_PERCENT', {
          percentCompleted,
          fileName
        })
        // onUploadProgress는 갱신될 때마다 해당 커밋을 수행한다.
        // console.log(percentCompleted);를 입력해보면 콘솔창에서 확인 가능하다.
      }
    }
  })
  showAxiosLoading(false)
  return result
}

export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gUploadFile = uploadFile
  }
}

// export { setPopPosition, changeDateFormat, postAxios }
