
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pTabList: {
      default: [] as object[]
    } as any,
    pChangeTab: Function,
    pSelectedTab: {} as any
  },
  created () {
    this.activetab = this.pSelectedTab
  },
  data () {
    return {
      transition: 'slide-next',
      activetab: 0,
      tabwidth: 4,
      touch: { sx: null, sy: null, st: null, ex: null, ey: null, et: null } as any,
      selectedTabName: ''
    }
  },
  methods: {
    switchtab (n : string | number, tab : any) {
      if (tab) {
        if (tab.dispName !== undefined && tab.dispName !== null && tab.dispName !== '') {
          this.selectedTabName = tab.dispName.trim()
        }
        if (this.pChangeTab) {
          this.pChangeTab(tab.tabType)
        }
      }
      this.activetab = Number(n)
    }
  },
  computed: {
    pointer () {
      if (window.PointerEvent) return true
      else return false
    },
    activebarWidth () {
      return {
        '--tabwidth': this.tabwidth + 'rem',
        '--transform': 'translateX(' + (this.activetab * this.tabwidth * 1) + 'rem' + ')'
      }
    }
  }
})
