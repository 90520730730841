
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pInputObj: {} as any,
    pInputType: {} as any,
    pInputIndex: {} as any,
    pPlaceHolder: {} as any,
    pDisabledYn: Boolean,
    pReadonly: {} as any
  },
  mounted() {
    if (this.pInputType === 'T') {
      const textref = this.$refs.commonTextArea as any
      textref.style.height = '1px'
      textref.style.height = textref.scrollHeight + 'px'
    }
  },
  data() {
    return {
      mInputObj: {} as any
    }
  },
  methods: {
    resize() {
      if (this.pInputType === 'T') {
        const textref = this.$refs.commonTextArea as any
        textref.style.height = '1px'
        textref.style.height = textref.scrollHeight + 'px'
      }
    },
    changeInput() {
      const selectBox = document.getElementById('selectInput') as any
      const selectedOption = {
        name: selectBox.options[selectBox.selectedIndex].text,
        key: selectBox.options[selectBox.selectedIndex].value
      }
      console.log('changed selectOption value ======', selectedOption)
      if (this.mInputObj.changeCallbackFn) {
        this.mInputObj.changeCallbackFn(selectedOption)
      }
    }
  },
  watch: {
    pInputObj: {
      immediate: true,
      handler(val) {
        if (!val) return
        this.mInputObj = val
      }
    }
  }
})
