
import { defineComponent } from 'vue'

/**
 * props 전달 예시
    {
      title: '팝업 제목',
      closeFn: () => {},
      size: { w: 600px, h: 100% },
      btnList: [
        { btnTitle: "신고하기", pointYn: false, onClickFn: () => {} },
        { btnTitle: "닫기", pointYn: false, onClickFn: () => {}}
      ],
      isFull?: boolean,
      isConfirm?: boolean,
      noHeader?: boolean,
      isBig?: boolean
    }
 */

export default defineComponent({
  props: {
    pPopInfo: Object as any,
    pZIndex: Number
  },
  data() {
    return {
      mScreenWidth: window.innerWidth,
      mScreenHeight: window.innerHeight
    }
  },
  computed: {
    reponsiveSize() {
      if (this.mScreenWidth >= 750) {
        return {
          width: this.pPopInfo.size.w,
          height: this.pPopInfo.size.h
        }
      } else if (this.pPopInfo.isFull) {
        return {
          width: '100%',
          height: '100%'
        }
      } else if (this.pPopInfo.isConfirm) {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 4 + 'px'
        }
      } else {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 6 + 'px'
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'hidden'
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount() {
    const wrapDom: any = document.querySelector('html')
    wrapDom.style.overflow = 'hidden auto'
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.mScreenWidth = window.innerWidth
    }
  }
})
