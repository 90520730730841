
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      // mSystemMsg: '오류가 발생했습니다.<br>잠시 후 다시 시도해주세요.<br>조사 서비스 패치시간: 19시 ~ 20시'
      mErrorStatus: '' as any,
      mSystemMsg: ''
    }
  },
  created () {
    if (this.$route.query && this.$route.query.errorStatus) {
      this.mErrorStatus = this.$route.query.errorStatus
      if (this.mErrorStatus === '404' || this.mErrorStatus === 404) {
        this.mSystemMsg = '<p>페이지를 찾을 수 없습니다. (404)</p><p class="commonSubTitleText" style="font-weight: normal;"><br>올바른 URL을 입력하였는지 확인해주세요.<br>감사합니다.</p>'
      }
    }
  }
})
