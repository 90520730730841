
import { defineComponent } from 'vue'
// import { setPopPosition } from '@/assets/ts/JOCommon'
export default defineComponent({
  props: {
    pPopHeaderText: String,
    pPopBodyText: String,
    pTimerFunction: Function,
    pBtnList: [] as any,
    pCloseFn: Function as any
  },
  data() {
    return {
      mPositionText: '',
      mPopSize: { popWidth: 330, popHeight: 250 }
    }
  },
  mounted() {
    this.mPositionText = this.$setPopPosition(
      this.mPopSize.popWidth,
      this.mPopSize.popHeight
    )
    window.addEventListener('resize', () => {
      this.mPositionText = this.$setPopPosition(
        this.mPopSize.popWidth,
        this.mPopSize.popHeight
      )
    })
    if (this.pTimerFunction) {
      const timerFunction = this.pTimerFunction
      setTimeout(() => {
        timerFunction()
      }, 2000)
    }
  }
})
