import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60360b32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "JOCommonConfirmHeader commonHeaderText" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "closeFn"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "JOCommonConfirmFooter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gPopFooter = _resolveComponent("gPopFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: "JOConfirmPopWrap",
    style: _normalizeStyle(_ctx.mPositionText)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", { innerHTML: _ctx.pPopHeaderText }, null, 8, _hoisted_2),
      (_ctx.pCloseFn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: "resource/images/common/JOCloseIcon.svg",
              alt: "닫기",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pCloseFn && _ctx.pCloseFn(...args)))
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "JOCommonConfirmBody commonNormalText textCenter",
      innerHTML: _ctx.pPopBodyText
    }, null, 8, _hoisted_4),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.pBtnList && _ctx.pBtnList.length > 0)
        ? (_openBlock(), _createBlock(_component_gPopFooter, {
            key: 0,
            pBtnList: _ctx.pBtnList
          }, null, 8, ["pBtnList"]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}