
import { thisTypeAnnotation } from '@babel/types'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pSearchTargetList: Function,
    pClosePop: Function as any,
    pParentCode: String, // 검색 팝업을 연 화면이 무엇인지 구분
    pSearchActorList: Function as any,
    pSearchValList: {} as any,
    pSelectedTab: {} as any,
    pJosa2Yn: Boolean,
    pAssignPopYn: {} as any,
    pSelectedActorInfo: {} as any,
    pJosaMenu: {} as any,
    pSearchSahooList: Function as any,
    pSearchSahooUserList: Function as any
  },
  created () {
    this.$store.dispatch('JO_USER/AC_ADD_HISTORY', { popId: 'commonSearchPop' })
    if (this.pParentCode === 'sahooList') {
      this.mSearchList = [
        { valiYn: false, modelName: 'searchSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchSelect', selectValueList: [{ title: '연번', value: 'houseNo' }, { title: '구연번', value: 'guHouseNo' }, { title: '관할구 선택', value: 'guCode' }], val: 'houseNo' as any, valiBase: [] },
        { valiYn: false, modelName: 'searchJusoSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchJusoSelect', selectValueList: [{ title: '도로주소', value: 'doroJuso' }, { title: '지번주소', value: 'dejiText' }], val: 'doroJuso' as any, valiBase: [] },
        { valiYn: true, modelName: 'step', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT_BUTTON, targetName: '조사형태', val: 'A' as any, valiBase: [], selectValueList: [{ title: '전체', value: 'A' }, { title: '4단계', value: '4' }, { title: '3단계', value: '3' }, { title: '2단계', value: '2' }, { title: '1단계', value: '1' }] }
      ]
      this.mJosaSearchList = [
        // { valiYn: true, modelName: 'sahuType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '설치 종류', val: 'A' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: 'A' }, { title: '차수', value: 'C' }, { title: '피난', value: 'P' }] },
        { valiYn: true, modelName: 'sahuStatus', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '단계 완료 여부', val: 'A' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '전체', value: 'A' }, { title: '설치동의 필요', value: '00' }, { title: '완료보고 필요', value: '10' }, { title: '완료승인 필요', value: '20' }, { title: '설치 완료', value: '99' }] as any },
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '설치자명', val: null as any, valiBase: [] }
      ]
      this.mJosaType = 'sahu'
      if (this.GE_USER && (this.GE_USER.authType === 'GG' || this.GE_USER.authType === 'GU')) {
        if (this.GE_USER.guCode) {
          this.mSearchList[0].selectValueList.splice(2)
        }
      }
    } else if (this.pParentCode === 'targetList') {
      this.mSearchList = [
        { valiYn: false, modelName: 'searchSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchSelect', selectValueList: [{ title: '연번', value: 'houseNo' }, { title: '구연번', value: 'guHouseNo' }, { title: '관할구 선택', value: 'guCode' }], val: 'houseNo' as any, valiBase: [] },
        { valiYn: false, modelName: 'searchJusoSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchJusoSelect', selectValueList: [{ title: '도로주소', value: 'doroJuso' }, { title: '지번주소', value: 'dejiText' }], val: 'doroJuso' as any, valiBase: [] },
        { valiYn: true, modelName: 'josa2Yn', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '조사종류', val: false as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '육안', value: false }, { title: '실측', value: true }] },
        { valiYn: true, modelName: 'josaStatus', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '조사상태', val: 'I' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '진행중', value: 'I' }, { title: '완료', value: 'C' }, { title: '전체', value: 'A' }] },
        { valiYn: true, modelName: 'step', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT_BUTTON, targetName: '조사형태', val: 'A' as any, valiBase: [], selectValueList: [{ title: '전체', value: 'A' }, { title: '4단계', value: '4' }, { title: '3단계', value: '3' }, { title: '2단계', value: '2' }, { title: '1단계', value: '1' }] }
      ]
      this.mJosaSearchList = [
        { valiYn: true, modelName: 'toolNeedType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '필요 설비 종류', val: '3' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: '3' }, { title: '차수', value: '1' }, { title: '피난', value: '2' }, { title: '불필요', value: '0' } as any] },
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
      ]
      this.mJosaType = true
    } else if (this.pParentCode === 'sahooUserList') {
      this.mSearchList = [
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] },
        { valiYn: true, modelName: 'encPhone', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '전화번호', val: null as any, valiBase: [{ type: 'NUM', baseVal: null }, { type: 'MIN', baseVal: 4 }, { type: 'MAX', baseVal: 4 }] },
        { valiYn: false, modelName: 'guCode', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '관할구 선택', val: 'A' as any, valiBase: [], selectValueList: this.mGuList },
        { valiYn: true, modelName: 'sahuType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT_BUTTON, targetName: '시설설치 타입', val: 'A' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '전체', value: 'A' }, { title: '차수', value: 'C' }, { title: '피난', value: 'P' }] }
      ]
      this.mPopSize = { popWidth: 460, popHeight: 450 }
      if (this.GE_USER && (this.GE_USER.authType === 'GG' || this.GE_USER.authType === 'GU')) {
        if (this.GE_USER.guCode) {
          this.mSearchList[2].val = this.GE_USER.guCode
        }
      }
    } else if (this.pParentCode === 'actorList') {
      this.mSearchList = [
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] },
        { valiYn: true, modelName: 'encPhone', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '전화번호', val: null as any, valiBase: [{ type: 'NUM', baseVal: null }, { type: 'MIN', baseVal: 4 }, { type: 'MAX', baseVal: 4 }] },
        { valiYn: false, modelName: 'guCode', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '관할구 선택', val: 'A' as any, valiBase: [], selectValueList: this.mGuList }
      ]
      this.mPopSize = { popWidth: 400, popHeight: 350 }
    }
    if (this.GE_JUSO_CODE) {
      for (let i = 0; i < this.GE_JUSO_CODE.length; i++) {
        this.mGuList.push({ title: this.GE_JUSO_CODE[i].guName, value: this.GE_JUSO_CODE[i].guCode })
      }
      this.mSelectGuCode.mSearchValList = this.mGuList
      console.log(this.mSelectGuCode)
    }
    if (this.pSearchValList) {
      console.log(this.pSearchValList)
      console.log(this.pParentCode)
    }
    //   if (this.pParentCode === 'sahooList' || this.pParentCode === 'targetList') {
    //     for (let i = 0; i < this.pSearchValList.length; i++) {
    //       console.log(this.pSearchValList[i].searchType === '연번')
    //       if (this.pSearchValList[i].searchType === '연번') {
    //         this.mSelectedInput = this.pSearchValList[i].searchValue
    //         this.mSearchList.val = 'houseNo'
    //       } else if (this.pSearchValList[i].searchType === '구연번') {
    //         this.mSelectedInput = this.pSearchValList[i].searchValue
    //         this.mSearchList.val = 'guHouseNo'
    //       } else if (this.pSearchValList[i].searchType === '관할구 선택') {
    //         this.mSearchList[2].val = this.pSearchValList[i].searchValue
    //         this.mSearchList.val = 'guCode'
    //       } else if (this.pSearchValList[i].searchType === '도로주소') {
    //         this.mSelectedInput = this.pSearchValList[i].searchValue
    //         this.mSearchList.val = 'doroJuso'
    //       } else if (this.pSearchValList[i].searchType === '지번주소') {
    //         this.mSelectedInput = this.pSearchValList[i].searchValue
    //         this.mSearchList.val = 'dejiText'
    //       } else if (this.pSearchValList[i].searchType === '설치자명') {
    //         this.mSearchList[1].val = this.pSearchValList[i].searchValue
    //       } else if (this.pSearchValList[i].searchType === '단계') {
    //         this.mSearchList[2].val = this.pSearchValList[i].searchValue
    //       }
    //     }
    //   } else if (this.pParentCode === 'actorList') {
    //     for (let i = 0; i < this.pSearchValList.length; i++) {
    //       if (this.pSearchValList[i].searchType === '조사자명') {
    //         this.mSearchList[0].val = this.pSearchValList[i].searchValue
    //       } if (this.pSearchValList[i].searchType === '전화번호') {
    //         this.mSearchList[1].val = this.pSearchValList[i].searchValue
    //       } if (this.pSearchValList[i].modelName === 'guCode') {
    //         this.mSearchList[2].val = this.pSearchValList[i].searchValue
    //       }
    //     }
    //   } else if (this.pParentCode === 'sahooUserList') {
    //     for (let i = 0; i < this.pSearchValList.length; i++) {
    //       if (this.pSearchValList[i].searchType === '조사자명') {
    //         this.mSearchList[0].val = this.pSearchValList[i].searchValue
    //       } else if (this.pSearchValList[i].searchType === '전화번호') {
    //         this.mSearchList[1].val = this.pSearchValList[i].searchValue
    //       } else if (this.pSearchValList[i].modelName === 'guCode') {
    //         this.mSearchList[2].val = this.pSearchValList[i].searchValue
    //       } else if (this.pSearchValList[i].modelName === 'sahuType') {
    //         this.mSearchList[3].val = this.pSearchValList[i].searchValue
    //       }
    //     }
    //   }
    // }
    if (this.GE_USER.authType === 'GU' || this.GE_USER.authType === 'GV') {
      const idx = this.mGuList.findIndex((item: any) => item.value === this.GE_USER.guCode)
      if (idx !== -1) this.mGuName = this.mGuList[idx].title
    }
  },
  data () {
    return {
      mSearchJusoSelect: { valiYn: false, modelName: 'searchJusoSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchJusoSelect', selectValueList: [{ title: '도로주소', value: 'doroJuso' }, { title: '지번주소', value: 'dejiText' }], val: 'doroJuso' as any, valiBase: [] },
      mSearchSelect: { valiYn: false, modelName: 'searchSelect', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: 'searchSelect', selectValueList: [{ title: '연번', value: 'houseNo' }, { title: '구연번', value: 'guHouseNo' }, { title: '관할구 선택', value: 'guCode' }], val: 'houseNo' as any, valiBase: [] },
      mSearchStep: { valiYn: true, modelName: 'step', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT_BUTTON, targetName: '조사형태', val: 'A' as any, valiBase: [], selectValueList: [{ title: '전체', value: 'A' }, { title: '4단계', value: '4' }, { title: '3단계', value: '3' }, { title: '2단계', value: '2' }, { title: '1단계', value: '1' }] },
      aaaa: { valiYn: true, modelName: 'josa2Yn', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '조사종류', val: true as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '육안', value: false }, { title: '실측', value: true }] } as any,
      bbbb: { valiYn: true, modelName: 'josaStatus', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '조사상태', val: 'C' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '진행중', value: 'I' }, { title: '완료', value: 'C' }, { title: '전체', value: 'A' }] },
      mJosaType: true as any,
      mSelectedInput: '',
      mSelectedJusoInput: '',
      mJosaSearchList: [
        { valiYn: true, modelName: 'toolNeedType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '필요 설비 종류', val: '3' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: '3' }, { title: '차수', value: '1' }, { title: '피난', value: '2' }, { title: '불필요', value: '0' } as any] },
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
      ],
      mGuSelectkBox: '',
      mSelectGuCode: { valiYn: false, modelName: 'guCode', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '관할구 선택', val: 'A' as any, valiBase: [], selectValueList: this.mGuList } as any,
      mGuName: '전체',
      mBtnList: [
        { btnTitle: '검색', onClickCallBack: this.setSearchKeyword, pointYn: true },
        { btnTitle: '닫기', onClickCallBack: this.closeMPop, pointYn: false }
      ],
      mPositionText: '',
      mPopSize: { popWidth: 700, popHeight: 500 },
      mSearchList: [] as any,
      mSearchKeyList: [] as any,
      mGuList: [{ title: '전체', value: 'A' }] as any,
      mDongList: [{ title: '전체', value: 'A' }] as any,
      mGuSiSearchToogleList: { valiYn: true, modelName: 'actor', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT_BUTTON, targetName: '구시검색', val: 'actor' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '조사자', value: 'actor' }, { title: '주소', value: 'addr' }] } as any,
      mGuSiSearchList: [
        { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] },
        { valiYn: true, modelName: 'phoneLast', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '전화번호', val: null as any, valiBase: [{ type: 'NUM', baseVal: null }, { type: 'MIN', baseVal: 4 }, { type: 'MAX', baseVal: 4 }] },
        { valiYn: false, modelName: 'doroJuso', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '도로주소', val: null as any, valiBase: [{ type: 'MIN', baseVal: 2 }] }
      ] as any
    }
  },
  methods: {
    closeMPop () {
      this.$store.dispatch('JO_USER/AC_DEL_HISTORY', { popId: 'commonSearchPop' })
      this.$store.dispatch('JO_USER/AC_CHANGE_RM_POPID', '')
      this.pClosePop()
    },
    setSearchKeyword () {
      const setSearchList = [] as any
      if (this.pParentCode === 'targetList' || this.pParentCode === 'sahooList') {
        if (this.mSearchSelect.val === 'guCode') {
          for (let j = 0; j < this.mGuList.length; j++) {
            if (this.mSelectGuCode.val === this.mGuList[j].value && this.GE_USER.authType !== 'GU') {
              setSearchList.push({ searchType: this.mSelectGuCode.targetName, modelName: this.mSelectGuCode.modelName, searchTitle: this.mGuList[j].title, searchValue: this.mSelectGuCode.val })
            }
          }
        } else if (this.mSearchSelect.val === 'houseNo' && this.mSelectedInput !== '') {
          console.log({ searchType: '연번', modelName: 'houseNo', searchValue: this.mSelectedInput })
          setSearchList.push({ searchType: '연번', modelName: 'houseNo', searchValue: this.mSelectedInput })
        } else if (this.mSearchSelect.val === 'guHouseNo' && this.mSelectedInput !== '') {
          setSearchList.push({ searchType: '구연번', modelName: 'guHouseNo', searchValue: this.mSelectedInput })
        }
        if (this.mSearchJusoSelect.val === 'doroJuso' && this.mSelectedJusoInput !== '') {
          setSearchList.push({ searchType: '도로주소', modelName: 'doroJuso', searchValue: this.mSelectedJusoInput })
        } else if (this.mSearchJusoSelect.val === 'dejiText' && this.mSelectedJusoInput !== '') {
          setSearchList.push({ searchType: '지번주소', modelName: 'dejiText', searchValue: this.mSelectedJusoInput })
        }
        if (this.mSearchStep.val !== 'A') {
          setSearchList.push({ searchType: '조사형태', modelName: 'step', searchValue: this.mSearchStep.val })
        }
        console.log(setSearchList)
        for (let i = 0; i < this.mJosaSearchList.length; i++) {
          console.log(this.mJosaSearchList[i].val !== 'A')
          console.log(this.mJosaSearchList[i])
          if (this.mJosaSearchList[i].modelName === 'sahuType') {
            if (this.mJosaSearchList[i].val !== 'A') {
              setSearchList.push({ searchType: this.mJosaSearchList[i].targetName, modelName: 'sahuType', searchValue: this.mJosaSearchList[i].val })
            }
          } else if (this.mJosaSearchList[i].modelName === 'sahuStatus') {
            if (this.mJosaSearchList[i].val !== 'A') {
              setSearchList.push({ searchType: this.mJosaSearchList[i].targetName, modelName: 'sahuStatus', searchValue: this.mJosaSearchList[i].val })
            }
          } else if (this.mJosaSearchList[i].modelName === 'dangerYn') {
            if (this.mJosaSearchList[i].val !== true) {
              setSearchList.push({ searchType: this.mJosaSearchList[i].targetName, modelName: 'dangerYn', searchValue: this.mJosaSearchList[i].val })
            }
          } else if (this.mJosaSearchList[i].modelName === 'toolNeedType') {
            if (this.mJosaSearchList[i].val !== '3') {
              setSearchList.push({ searchType: this.mJosaSearchList[i].targetName, modelName: 'toolNeedType', searchValue: this.mJosaSearchList[i].val })
            }
          } else {
            if (this.mJosaSearchList[i].val) {
              setSearchList.push({ searchType: this.mJosaSearchList[i].targetName, modelName: this.mJosaSearchList[i].modelName, searchValue: this.mJosaSearchList[i].val })
            }
          }
        }
        console.log(setSearchList)
      } else if (this.pParentCode === 'actorList' || this.pParentCode === 'sahooUserList') {
        for (let i = 0; i < this.mSearchList.length; i++) {
          console.log(this.mSearchList[i])
          console.log(this.mSearchList[i].val)
          if (this.mSearchList[i].modelName === 'guCode' && this.mSearchList[i].val !== 'A') {
            for (let j = 0; j < this.mGuList.length; j++) {
              if (this.mSearchList[i].val === this.mGuList[j].value && this.GE_USER.authType !== 'GU') {
                setSearchList.push({ searchType: this.mSelectGuCode.targetName, modelName: this.mSelectGuCode.modelName, searchTitle: this.mGuList[j].title, searchValue: this.mSearchList[i].val })
              }
            }
          } else if (this.mSearchList[i].modelName === 'userName' && this.mSearchList[i].val !== null) {
            setSearchList.push({ searchType: this.mSearchList[i].targetName, modelName: this.mSearchList[i].modelName, searchValue: this.mSearchList[i].val })
          } else if (this.mSearchList[i].modelName === 'encPhone' && this.mSearchList[i].val !== null) {
            setSearchList.push({ searchType: this.mSearchList[i].targetName, modelName: this.mSearchList[i].modelName, searchValue: this.mSearchList[i].val })
          } else if (this.mSearchList[i].modelName === 'sahuType' && this.mSearchList[i].val !== 'A') {
            setSearchList.push({ searchType: this.mSearchList[i].targetName, modelName: this.mSearchList[i].modelName, searchValue: this.mSearchList[i].val })
          }
        }
        console.log(setSearchList)
      }
      this.mSearchKeyList = setSearchList
      if (this.pParentCode === 'targetList') {
        if (this.pSearchTargetList) {
          this.pSearchTargetList(this.mSearchKeyList)
        }
      } else if (this.pParentCode === 'actorList') {
        if (this.pSearchActorList) {
          this.pSearchActorList(this.mSearchKeyList)
        }
      } else if (this.pParentCode === 'sahooList') {
        if (this.pSearchSahooList) {
          this.pSearchSahooList(this.mSearchKeyList)
        }
      } else if (this.pParentCode === 'sahooUserList') {
        if (this.pSearchSahooUserList) {
          this.pSearchSahooUserList(this.mSearchKeyList)
        }
      }
      console.log(this.mSearchKeyList)
      this.closeMPop()
    }
  },
  computed: {
    GE_USER () {
      return this.$store.getters['JO_USER/GE_USER']
    },
    GE_JUSO_CODE () {
      return this.$store.getters['JO_JOSA/GE_JUSO_CODE']
    },
    GE_RM_POP_ID () : any {
      return this.$store.getters['JO_USER/GE_RM_POP_ID']
    }
  },
  watch: {
    GE_RM_POP_ID: {
      handler (val) {
        if (val && val !== '') {
          if (val === 'commonSearchPop') {
            this.closeMPop()
          }
        }
      },
      deep: true
    },
    mSearchSelect: {
      handler (val) {
        if (!val) return
        if (val.val === 'guCode') {
          this.mGuSelectkBox = 'guCode'
          this.mSelectGuCode.selectValueList = this.mGuList
        } else this.mGuSelectkBox = ''
      },
      deep: true
    },
    aaaa: {
      handler (val) {
        if (val.val && this.pParentCode === 'targetList') {
          this.mJosaType = val.val
          this.mJosaSearchList = [
            { valiYn: true, modelName: 'toolNeedType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '필요 설비 종류', val: '3' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: '3' }, { title: '차수', value: '1' }, { title: '피난', value: '2' }, { title: '불필요', value: '0' } as any] },
            { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
          ]
        } else if (!val.val && this.pParentCode === 'targetList') {
          this.mJosaType = val.val
          this.mJosaSearchList = [
            { valiYn: true, modelName: 'dangerYn', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '반지하 여부', val: true as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '예', value: true }, { title: '아니오', value: false } as any] },
            { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
          ]
        }
      },
      deep: true
    },
    mSearchList: {
      handler (val) {
        if (!val) return
        if (val[0].val === 'guCode') {
          this.mGuSelectkBox = 'guCode'
          this.mSelectGuCode.selectValueList = this.mGuList
        } else this.mGuSelectkBox = ''
        // if (val[1].val && this.pParentCode === 'targetList') {
        //   this.mJosaType = val[1].val
        //   alert('c')
        //   this.mJosaSearchList = [
        //     { valiYn: true, modelName: 'toolNeedType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '필요 설비 종류', val: '3' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: '3' }, { title: '차수', value: '1' }, { title: '피난', value: '2' }, { title: '불필요', value: '0' } as any] },
        //     { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
        //   ]
        // } else if (!val[1].val && this.pParentCode === 'targetList') {
        //   this.mJosaType = val[1].val
        //   alert('d')
        //   this.mJosaSearchList = [
        //     { valiYn: true, modelName: 'dangerYn', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '반지하 여부', val: true as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '예', value: true }, { title: '아니오', value: false } as any] },
        //     { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '조사자명', val: null as any, valiBase: [] }
        //   ]
        // } else {
        //   this.mJosaType = 'sahu'
        //   this.mJosaSearchList = [
        //     // { valiYn: true, modelName: 'sahuType', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '설치 종류', val: 'A' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '모두', value: 'A' }, { title: '차수', value: 'C' }, { title: '피난', value: 'P' }] },
        //     { valiYn: true, modelName: 'sahuStatus', inputType: this.$JOConst.VALUE_TAG_TYPE_SELECT, targetName: '단계 완료 여부', val: 'A' as any, valiBase: [{ type: this.$JOConst.VALI_CHECK_TYPE_NOT_NULL, baseVal: null }], selectValueList: [{ title: '전체', value: 'A' }, { title: '설치동의 필요', value: '00' }, { title: '완료보고 필요', value: '10' }, { title: '완료승인 필요', value: '20' }, { title: '설치 완료', value: '99' }] as any },
        //     { valiYn: false, modelName: 'userName', inputType: this.$JOConst.VALUE_TAG_TYPE_INPUT_TEXT, targetName: '설치자명', val: null as any, valiBase: [] }
        //   ]
        // }
      },
      deep: true
    }
  },
  mounted () {
    this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    window.addEventListener('resize', () => {
      this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    })
  }
})
