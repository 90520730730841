/* eslint-disable */
import store from '@/store'
import { postAxios } from '../../../public/resource/ts/JOCommon'
/*

 서비스 연동을 위한 js import
    <script type="text/javascript" src="https://mo.d-alim.com/D_service.js"></script>
 */

/*
* 게시판 바로가기 api 활용방법

    *행위: (2)새움소프트 회사에서 (1)오피스온 내에 있는 버튼을 통해 더알림내 (3)오피스온 오류게시판을 접속
        - (1) 오피스온: 파트너사(더알림/팀플코어)
            - (필수)더알림으로부터, partnerToken을 발급받아 db에 저장하거나 보이지 않게 하드코딩한다.
        - (2) 새움소프트: 내 채널
            - (필수)오피스온의 새움소프트회사가 더알림과 연동하기 위해서는, 더알림에 새움소프트 채널을 생성해야한다.
            - (필수)더알림으로부터, 새움소프트 채널의 chnnelId를 발급받아 db에 저장하거나 보이지 않게 하드코딩 한다.
            - (선택)새움소프트 채널설정 > 구독자관리 > 멤버유형:
        - (3) 오피스온 오류게시판: 타겟채널의 게시판
            - 더알림으로부터, chnnelId를 발급받아 db에 저장하거나 보이지 않게 하드코딩 한다.

 */

/*    1. (오피스온 오류접수)게시판 바로가기 버튼 클릭시 실행되는 함수 */
function openBoardMain(): void {
  const param: any = registerDService()

  param.toTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.cabinetKey = 13193 // 조사No.1 > 고객센터 게시판

  // 로그인 정보가 없을 때 로그인 화면에 나타낼 안내메세지
  param.loginText = '조사No.1의 서비스문의 게시판으로 이동중입니다'
  // @ts-ignore
  dService.openBoardMain(param, saveDServiceAccessInfo)
  // saveDServiceAccessInfo: 유저 토큰 업데이트시 리턴받을 함수 (게시판 오픈 요청을 했으나, 로그인정보가 없으면 로그인처리 후 토큰을 리턴해줌)
}

function openGuBoardMain(): void {
  const param: any = registerDService()

  param.toTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.cabinetKey = 13197 // 조사No.1 > 고객센터 게시판

  // 로그인 정보가 없을 때 로그인 화면에 나타낼 안내메세지
  param.loginText = '조사No.1의 주택관련문의 게시판으로 이동중입니다'
  // @ts-ignore
  dService.openBoardMain(param, saveDServiceAccessInfo)
  // saveDServiceAccessInfo: 유저 토큰 업데이트시 리턴받을 함수 (게시판 오픈 요청을 했으나, 로그인정보가 없으면 로그인처리 후 토큰을 리턴해줌)
}

function openIntroduceServiceMain(): void {
  const param: any = registerDService()

  param.toTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.cabinetKey = 13203 // 조사No.1 > 도입문의 게시판

  // 로그인 정보가 없을 때 로그인 화면에 나타낼 안내메세지
  param.loginText = '조사No.1의 서비스도입문의 게시판으로 이동중입니다'
  // @ts-ignore
  dService.openBoardMain(param, saveDServiceAccessInfo)
  // saveDServiceAccessInfo: 유저 토큰 업데이트시 리턴받을 함수 (게시판 오픈 요청을 했으나, 로그인정보가 없으면 로그인처리 후 토큰을 리턴해줌)
}
/*    2. 더알림(팀플코어)으로부터 유저정보를 받을 콜백리슨함수 정의 */
async function saveDServiceAccessInfo(data: {
  uAccessToken: string
  partnerToken: string
}) {
  // data = {uAccessToken: string, partnerToken: string}
  const uAccessToken = data.uAccessToken // 오피스온 > 더알림 진입시 로그인 인증에 필요 (유저 토큰값)
  const partnerToken = data.partnerToken // 오피스온의 더알림 파트너 정보 (더알림으로 들어오는 진입점이 되는 서비스 인증에 필요)
  const param = { user: {} } as any
  const user = store.getters['JO_USER/GE_USER']
  user.uAccessToken = uAccessToken
  param.user.tpUserToken = uAccessToken
  param.user.userKey = user.userKey
  const result = await postAxios(
    'https://www.josa1.kr:9443/josa1/jo.saveUser',
    param
  )
  if (result.data.result) {
    store.dispatch('JO_USER/AC_USER', user)
  } else {
  }
  // 파트너사(오피스온) 인증값 : 보이지 않는 곳에 하드코딩 or db에 저장 (테스트를 위해 임의로 localstorage에 저장)
  if (partnerToken) localStorage.setItem('partnerToken', partnerToken)

  // 오피스온db 유저정보 필드에 저장 (테스트를 위해 임의로 localstorage에 저장)
  if (uAccessToken) localStorage.setItem('uAccessToken', uAccessToken)
}

/* 0. 더알림 파트너 정보 처리 */

function registerDService() {
  // api 사용을 위해 기본적으로 꼭 필요한 정보들을 set해주는 함수 정의
  const partnerToken = 'jtpltMQCq8Y5lVQHepuIAQ=='
  let uAccessToken: any = '' // 오피스온에서 더알림 계정에 접근하기 위한 토큰값

  const user = store.getters['JO_USER/GE_USER']
  // (테스트를 위해 임의로 localstorage에 저장) db에 저장되어있는 유저의 더알림 인증값
  if (user.uAccessToken) uAccessToken = user.uAccessToken

  // 더알림과의 양방향 상호작용이 일어날 때를 대비한 정보 (현재 중요한 값은 x)
  /*     --> 오피스온 내에서 해당 유저의 기본정보값을 전달(string값으로)  */

  const keepInfObj = user
  const keppInfoStr = JSON.stringify(keepInfObj)
  // 286 시스템관리자(249 소속 276 이름 277 휴대폰번호)
  // 287 건축사 협회 (250 소속 251 이름 252 휴대폰번호)
  // 288 시청직원 (253 시청명 254 이름 255 휴대폰번호 267권한)
  // 290 구청직원 (259 시청명 260 구청명 261 이름 262 휴대폰번호 269권한)
  // 292 조사자 (270 소속 271 이름 272 휴대폰번호)
  const memParam: any = new Object()
  memParam.teamId = '+vh6u6DssILFkfcsrB6Pmw=='
  if (user.authType === 'AD') {
    memParam.memberType = 'TYPE0286'
    memParam.ITEM0249 = '팀플'
    memParam.ITEM0276 = user.userName
    memParam.ITEM0277 = user.encPhone
  } else if (user.authType === 'HY') {
    memParam.memberType = 'TYPE0287'
    memParam.ITEM0250 = '건축사'
    memParam.ITEM0251 = user.userName
    memParam.ITEM0252 = user.encPhone
  } else if (user.authType === 'SV' || user.authType === 'SA') {
    memParam.memberType = 'TYPE0288'
    memParam.ITEM0253 = '서울시청'
    memParam.ITEM0254 = user.userName
    memParam.ITEM0255 = user.encPhone
    if (user.authType === 'SV') {
      memParam.ITEM0267 = '조회자'
    } else if (user.authType === 'SA') {
      memParam.ITEM0267 = '담당자'
    }
  } else if (user.authType === 'GV' || user.authType === 'GU') {
    // 290 구청직원 (259 시청명 260 구청명 261 이름 262 휴대폰번호 269권한)
    memParam.memberType = 'TYPE0290'
    memParam.ITEM0259 = '서울시청'
    memParam.ITEM0260 = user.guName
    memParam.ITEM0261 = user.userName
    memParam.ITEM0262 = user.encPhone
    if (user.authType === 'GV') {
      memParam.ITEM0269 = '조회자'
    } else if (user.authType === 'GU') {
      memParam.ITEM0269 = '담당자'
    }
  } else if (user.authType === 'JO') {
    // 292 조사자 (270 소속 271 이름 272 휴대폰번호)
    memParam.memberType = 'TYPE0292'
    memParam.ITEM0270 = '건축사'
    memParam.ITEM0271 = user.userName
    memParam.ITEM0272 = user.encPhone
  } else {
    /* user.userKey = 726
    user.userName = '익명도입문의'
    user.encPhone = '01042164216' */
  }
  const memberParamListStr = JSON.stringify(memParam)

  const regiParam = {} as {
    partnerToken: string
    uAccessToken: string
    memberParamListStr: string
    keepInfo: string
    userName: string
    encPhone: string
  }
  regiParam.partnerToken = partnerToken
  regiParam.uAccessToken = uAccessToken
  regiParam.memberParamListStr = memberParamListStr
  regiParam.keepInfo = keppInfoStr
  regiParam.userName = user.userName
  regiParam.encPhone = user.encPhone
  return regiParam
}
/* function openIntroduceServiceMain (): void {
    const param : any = registerDService()

    param.toTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
    param.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
    param.cabinetKey = 13203 // 조사No.1 > 도입문의 게시판

    // 로그인 정보가 없을 때 로그인 화면에 나타낼 안내메세지
    param.loginText = '조사No.1의 서비스도입문의 게시판으로 이동중입니다'
    // @ts-ignore
    dService.openBoardMain(param, saveDServiceAccessInfo)
    // saveDServiceAccessInfo: 유저 토큰 업데이트시 리턴받을 함수 (게시판 오픈 요청을 했으나, 로그인정보가 없으면 로그인처리 후 토큰을 리턴해줌)
  } */
async function saveBoardContents(iParam: any, callback: any) {
  const param: any = registerDService()
  param.toTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  param.memberParamListStr = param.memberParamListStr
  param.keepInfo = JSON.stringify(param.keepInfo)

  const partnerToken = 'jtpltMQCq8Y5lVQHepuIAQ=='
  const uAccessToken = 'Ni+ggxy4ajaRMt5i46o4Fw=='
  param.uAccessToken = uAccessToken
  param.partnerToken = partnerToken
  param.userKey = 726
  param.userName = '익명도입문의'

  param.userName = '익명도입문의'
  param.encPhone = '01042164216'
  // inputName , inputPhone 필요
  // title
  // cabinetName
  // cabinetKey
  var content = {} as any
  content.creUserKey = 726
  content.creUserName = '익명도입문의'
  content.bodyFullStr = iParam.bodyFullStr
  content.title = iParam.title
  content.cabinetKey = 13203 // 조사No.1 > 도입문의 게시판
  // iParam.myTeamId = '+vh6u6DssILFkfcsrB6Pmw=='
  // @ts-ignore
  dService.saveBoardContents(param, content, callback)
}
export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gOpenBoardMain = openBoardMain
    Vue.config.globalProperties.$gOpenIntroduceServiceMain =
      openIntroduceServiceMain
    Vue.config.globalProperties.$gOpenGuBoardMain = openGuBoardMain
    Vue.config.globalProperties.$gSaveDServiceAccessInfo =
      saveDServiceAccessInfo
    Vue.config.globalProperties.$gRegisterDService = registerDService
    Vue.config.globalProperties.$gSaveBoardContents = saveBoardContents
  }
}
