
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pGetInfo: Function, // 페이지 이동 시 실행하며 데이터를 업데이트시켜줄 함수
    pPageInfo: {} as any, // result 값으로 넘어온 페이지에 대한 정보를 저장
    pOpenConfirmPop: Function, // 범위를 벗어나게 이동하는 경우 confirm popup을 띄워주기 위한 함수,
    pTargetYn: Object
  },
  data () {
    return {
      mCurrentPage: 1
    }
  },
  watch: {
    mCurrentPage: {
      handler () {
        if (this.pGetInfo) {
          this.pGetInfo(this.mCurrentPage) // 페이지 이동이 성공할 때마다 데이터를 업데이트 시켜주는 함수를 실행
        }
      },
      deep: true
    },
    pPageInfo: {
      handler () {
        this.mCurrentPage = this.pPageInfo.pageNumber + 1
        console.log(this.pPageInfo)
      },
      deep: true
    }
  },
  methods: {
    resetCurrPage () {
      this.mCurrentPage = 1
    },
    pageMove (moveDirection: string): void {
      if (this.pPageInfo) {
        if (moveDirection === 'next') {
          if (this.mCurrentPage < this.pPageInfo.totalPages) {
            this.mCurrentPage += 1
          } else {
            if (this.pOpenConfirmPop) {
              this.pOpenConfirmPop('마지막 페이지입니다')
            }
          }
        } else if (moveDirection === 'prev') {
          if (this.mCurrentPage === 1) {
            if (this.pOpenConfirmPop) {
              this.pOpenConfirmPop('첫 번째 페이지입니다')
            }
            return
          }
          this.mCurrentPage -= 1
        }
      }
    }
  }
})
