
import { defineComponent } from 'vue'
// import { setPopPosition } from '@/assets/ts/JOCommon'
export default defineComponent({
  data () {
    return {
      mPositionText: '',
      mPopSize: { popWidth: 500, popHeight: 350 }
    }
  },
  props: {
    pPopHeaderText: {},
    pPopBodyText: {},
    pClosePop: Function as any,
    pTimerFunction: Function,
    pBtnList: [] as any
  },
  mounted () {
    this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    window.addEventListener('resize', () => {
      this.mPositionText = this.$setPopPosition(this.mPopSize.popWidth, this.mPopSize.popHeight)
    })
    if (this.pTimerFunction) {
      const timerFunction = this.pTimerFunction
      setTimeout(() => {
        timerFunction()
      }, 2000)
    }
  }
})
