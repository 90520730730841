
import { defineComponent } from 'vue'

// 넘겨받을 props 형태
/**
  [
    {btnTitle: string, pointYn: boolean ,onClickFn: Function},
    {btnTitle: string, pointYn: boolean ,onClickFn: Function},
  ]
*/

export default defineComponent({
  props: {
    pBtnList: Array as any
  }
})
