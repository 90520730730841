interface joUser {
    sessionUserInfo: any,
    jusoCode: any,
    popHistory: any,
    removePopId: any
}
const JO_USER = {
  namespaced: true,
  state: {
    sessionUserInfo: {
    },
    popHistory: [],
    jusoCode: {
      SI: [],
      GU: [],
      DONG: []
    },
    loginTime: ''
  },
  getters: {
    GE_USER (state: joUser) {
      const userInfo = state.sessionUserInfo
      if (userInfo.authType === 'JO') {
        userInfo.authName = '조사자'
      } else if (userInfo.authType === 'GV') {
        userInfo.authName = '구 조회자'
      } else if (userInfo.authType === 'GU') {
        userInfo.authName = '구 담당자'
      } else if (userInfo.authType === 'SA') {
        userInfo.authName = '시 담당자'
      } else if (userInfo.authType === 'SV') {
        userInfo.authName = '시 조회자'
      } else if (userInfo.authType === 'HY') {
        userInfo.authName = '협회'
      } else if (userInfo.authType === 'AD') {
        userInfo.authName = '시스템관리자'
      }
      /* JO : 조사자
GV : 구 조회자
GU : 구 담당자
SV : 시 조회자
SA : 시 담당자
HY : 협회
AD : 시스템담당자 */
      return userInfo
    },
    GE_RM_POP_ID (state : joUser) {
      return state.removePopId
    },
    GE_JUSO_CODE (state: joUser) {
      const jusoCode = state.jusoCode
      return jusoCode
    },
    GE_SI (state: joUser) {
      const jusoCode = state.jusoCode
      return jusoCode.SI
    },
    GE_GU (state: joUser) {
      const jusoCode = state.jusoCode
      return jusoCode.GU
    },
    GE_DONG (state: joUser) {
      const jusoCode = state.jusoCode
      return jusoCode.DONG
    },
    GE_LOGIN_TIME (state: any) {
      const loginTime = state.loginTime
      return loginTime
    },
    GE_POP_HISTORY (state: joUser) {
      const popHistory = state.popHistory
      return popHistory
    }
  },
  mutations: {
    MU_CHANGE_RM_POPID (state : joUser, payload: any) {
      state.removePopId = payload
    },
    MU_ADD_HISTORY (state : joUser, payload: any) {
      state.popHistory.push(payload)
    },
    MU_CLEAR_HISTORY (state : joUser, payload: any) {
      state.popHistory = []
    },
    MU_DEL_HISTORY (state : joUser, payload: any) {
      const index : number = state.popHistory.findIndex((item: any) => item.popId === payload.popId)
      if (index !== -1) {
        state.popHistory.splice(index, 1)
      }
    },
    MU_USER (state : joUser, payload: any) {
      console.log(payload)
      state.sessionUserInfo = payload
    },
    MU_JUSO_CODE (state : joUser, payload: any) {
      state.jusoCode = payload
    },
    MU_USER_CHANGE_ROLE_KIND (state : joUser, payload: string) {
      state.sessionUserInfo.authType = payload
      if (payload === 'JO') {
        state.sessionUserInfo.userKey = 0
        state.sessionUserInfo.userName = '황수민'
        state.sessionUserInfo.authType = '조사자'
      } else if (payload === 'GV') {
        state.sessionUserInfo.userKey = 1
        state.sessionUserInfo.userName = '최유민'
        state.sessionUserInfo.authType = '구 조회자'
      } else if (payload === 'GU') {
        state.sessionUserInfo.userKey = 2
        state.sessionUserInfo.userName = '백지수'
        state.sessionUserInfo.authType = '구 담당자'
      } else if (payload === 'SA') {
        state.sessionUserInfo.userKey = 3
        state.sessionUserInfo.userName = '신민섭'
        state.sessionUserInfo.authType = '시 담당자'
      } else if (payload === 'HY') {
        state.sessionUserInfo.userKey = 4
        state.sessionUserInfo.userName = '최병진'
        state.sessionUserInfo.authType = '협회'
      } else if (payload === 'AD') {
        state.sessionUserInfo.userKey = 5
        state.sessionUserInfo.userName = '최병진'
        state.sessionUserInfo.authType = '시스템관리자'
      }
    },
    MU_USER_CLEAR (state : joUser) {
      // state.roleListMap = {}
      state.sessionUserInfo = {}
    },
    MU_LOGIN_TIME (state : any, payload: string) {
      state.loginTime = payload
    }
  },
  actions: {
    AC_CHANGE_RM_POPID ({ commit } :any, payload: object) {
      commit('MU_CHANGE_RM_POPID', payload)
    },
    AC_DEL_HISTORY ({ commit } :any, payload: object) {
      commit('MU_DEL_HISTORY', payload)
    },
    AC_ADD_HISTORY ({ commit } :any, payload: object) {
      commit('MU_ADD_HISTORY', payload)
    },
    AC_USER ({ commit } :any, payload: object) {
      commit('MU_USER', payload)
    },
    AC_USER_CLEAR ({ commit } :any, payload: object) {
      commit('MU_USER_CLEAR', payload)
    },
    AC_JUSO_CODE ({ commit } :any, payload: object) {
      commit('MU_JUSO_CODE', payload)
    },
    AC_USER_CHANGE_ROLE_KIND ({ commit } :any, payload: string) {
      commit('MU_USER_CHANGE_ROLE_KIND', payload)
    },
    AC_LOGIN_TIME ({ commit } :any, payload: string) {
      commit('MU_LOGIN_TIME', payload)
    }
  }
}

export default JO_USER
