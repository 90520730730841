import { postAxios } from '../../public/resource/ts/JOCommon'
interface JoJosa {
  JOSA: {
    JOSA_LIST: any[]
    HOUSE_LIST: any[]
    JOSA_DETAIL: any[]
    jusoCodeList: any[]
    DONG_LIST: any[]
  }
}
const JO_JOSA = {
  namespaced: true,
  state: {
    JOSA: {
      JOSA_LIST: [],
      JOSA_DETAIL: [],
      HOUSE_LIST: [],
      jusoCodeList: [],
      DONG_LIST: [],
      UPLOAD_PERCENT: {}
    }
  },
  getters: {
    GE_JOSA(state: JoJosa) {
      return state.JOSA
    },
    GE_ONLY_JOSA_LIST(state: JoJosa) {
      const josaList = state.JOSA.JOSA_LIST
      for (let i = 0; i < josaList.length; i++) {
        const josa = josaList[i]
        const houseKey = josa.houseKey
        const index = state.JOSA.HOUSE_LIST.findIndex(
          (item) => item.houseKey === houseKey
        )
        if (index !== -1) {
          josaList[i].HOUSE_INFO = state.JOSA.HOUSE_LIST[index]
        }
      }
      return josaList
    },
    GE_ONLY_HOUSE_LIST(state: JoJosa) {
      const houseList = state.JOSA.HOUSE_LIST
      for (let i = 0; i < houseList.length; i++) {
        const house = houseList[i]
        const josaKey = house.josaKey
        const index = state.JOSA.JOSA_LIST.findIndex(
          (item) => item.josaKey === josaKey
        )
        if (index !== -1) {
          houseList[i].JOSA = state.JOSA.HOUSE_LIST[index]
        }
      }
      return houseList
    },
    GE_JUSO_CODE(state: JoJosa) {
      const jusoCode = state.JOSA.jusoCodeList
      return jusoCode
    },
    GE_DONG_LIST(state: JoJosa) {
      const dongData = state.JOSA.DONG_LIST
      return dongData
    },
    GE_UPLOAD_PERCENT(state: any) {
      const uploadPercent = state.JOSA.UPLOAD_PERCENT
      return uploadPercent
    },
    GE_JOSA_DETAIL(state: any) {
      const jusoCode = state.JOSA.JOSA_DETAIL
      return jusoCode
    }
  },
  mutations: {
    MU_ONLY_ADD_HOUSE(state: JoJosa, payload: any) {
      const houseList = state.JOSA.HOUSE_LIST
      if (!payload || payload.length === 0) {
        for (let i = 0; i < payload.length; i++) {
          const josa = payload[i]
          const index = houseList.findIndex(
            (item) => item.houseKey === josa.houseKey
          )
          if (index !== -1) {
            // josaList[index]
          } else {
            houseList.push(josa)
          }
        }
      }
      state.JOSA.HOUSE_LIST = houseList
    },
    MU_REPLACE_ADD_HOUSE(state: JoJosa, payload: any) {
      const houseList = state.JOSA.HOUSE_LIST
      if (!payload || payload.length === 0) {
        for (let i = 0; i < payload.length; i++) {
          const josa = payload[i]
          const index = houseList.findIndex(
            (item) => item.houseKey === josa.houseKey
          )
          if (index !== -1) {
            houseList[index] = josa
          } else {
            houseList.push(josa)
          }
        }
      }
      state.JOSA.HOUSE_LIST = houseList
    },
    MU_ONLY_ADD_JOSA(state: JoJosa, payload: any) {
      const josaList = state.JOSA.JOSA_LIST
      if (!payload || payload.length === 0) return
      for (let i = 0; i < payload.length; i++) {
        const josa = payload[i]
        const index = josaList.findIndex(
          (item) => item.josaKey === josa.josaKey
        )
        let houseDepthTypeText = josa.houseDepthType
        if (josa.houseDepthType === '3') {
          houseDepthTypeText = '평지에서 2/3이상 지하'
        } else if (josa.houseDepthType === '2') {
          houseDepthTypeText = '평지에서 1/2 정도 지하'
        } else if (josa.houseDepthType === '1') {
          houseDepthTypeText = '경사지로서 1면 이상 지상'
        } else if (josa.houseDepthType === '0') {
          houseDepthTypeText = '기타'
        }
        josa.houseDepthTypeText = houseDepthTypeText
        let dangerLevelText = josa.dangerLevel
        if (josa.dangerLevel === '99') {
          dangerLevelText = '매우'
        } else if (josa.dangerLevel === '12') {
          dangerLevelText = '보통'
        } else if (josa.dangerLevel === '11') {
          dangerLevelText = '약간'
        } else if (josa.dangerLevel === '01') {
          dangerLevelText = '불필요'
        } else {
          dangerLevelText = '-'
        }
        josa.dangerLevelText = dangerLevelText
        let houseTypeText = josa.houseType
        if (houseTypeText === 'YR') {
          houseTypeText = '연립주택'
        } else if (houseTypeText === 'DS') {
          houseTypeText = '다세대'
        } else if (houseTypeText === 'DJ') {
          houseTypeText = '다중주택'
        } else if (houseTypeText === 'DG') {
          houseTypeText = '다가구'
        } else if (houseTypeText === 'DD') {
          houseTypeText = '단독주택'
        } else if (houseTypeText === 'AP') {
          houseTypeText = '아파트'
        }
        josa.houseTypeText = houseTypeText
        let toolNeedTypeText = josa.toolNeedType
        if (toolNeedTypeText === '3') {
          toolNeedTypeText = '차수&피난'
        } else if (toolNeedTypeText === '2') {
          toolNeedTypeText = '차수'
        } else if (toolNeedTypeText === '1') {
          toolNeedTypeText = '피난'
        } else if (toolNeedTypeText === '0') {
          toolNeedTypeText = '불필요'
        }
        josa.toolNeedTypeText = toolNeedTypeText
        const dejiTextString = josa.dejiText
        if (dejiTextString) {
          const dejiTextList = dejiTextString.split(' ')
          dejiTextList.splice(0, 2)
          let dejiString = ''
          for (let i = 0; i < dejiTextList.length; i++) {
            dejiString += dejiTextList[i] + ' '
          }
          josa.dejiTextString = dejiString
        }
        const doroJusoString = josa.doroJuso
        if (doroJusoString) {
          const doroJusoList = doroJusoString.split(' ')
          doroJusoList.splice(0, 2)
          let doroString = ''
          for (let i = 0; i < doroJusoList.length; i++) {
            doroString += doroJusoList[i] + ' '
          }
          josa.doroJusoString = doroString
        }
        if (index !== -1) {
          josaList[index].toolNeedType = josa.toolNeedType
          josaList[index].toolNeedTypeText = josa.toolNeedTypeText
          josaList[index].damageYn = josa.damageYn
          josaList[index].houseDepthType = josa.houseDepthType
          josaList[index].houseDepthTypeText = josa.houseDepthTypeText
          if (josa.guHouseNo) josaList[index].guHouseNo = josa.guHouseNo
          if (josa.josaPicFileList) {
            josaList[index].josaPicFileList = josa.josaPicFileList
          }
          if (josa.josaPicMfilekey) {
            josaList[index].josaPicMfilekey = josa.josaPicMfilekey
          }
          josaList[index].damageCount = josa.damageCount
          josaList[index].dangerLevel = josa.dangerLevel
          josaList[index].dangerLevelText = josa.dangerLevelText
          josaList[index].josaStatus = josa.josaStatus
          josaList[index].josaStep = josa.josaStep
          josaList[index].josaUserKey = josa.josaUserKey
          josaList[index].josaKey = josa.josaKey
          josaList[index].josa2Key = josa.josa2Key
          josaList[index].josa2UserKey = josa.josa2UserKey
          josaList[index].depthType = josa.depthType
          josaList[index].depthTypeText = josa.depthTypeText
          josaList[index].houseType = josa.houseType
          josaList[index].houseKey = josa.houseKey
          josaList[index].houseTypeText = josa.houseTypeText
          josaList[index].sgdName = josa.sgdName
          josaList[index].josaUserName = josa.josaUserName
          josaList[index].encPhone = josa.encPhone
          josaList[index].josa2UserName = josa.josa2UserName
          josaList[index].dejiText = josa.dejiText
          josaList[index].bdName = josa.bdName
          josaList[index].dejiTextString = josa.dejiTextString
          josaList[index].doroJuso = josa.doroJuso
          josaList[index].doroJusoString = josa.doroJusoString
          josaList[index].extraInfo = josa.extraInfo
          josaList[index].josaComment = josa.josaComment
          josaList[index].summComment = josa.summComment
          josaList[index].startDate = josa.startDate
          josaList[index].updUserKey = josa.updUserKey
          josaList[index].updUserName = josa.updUserName
          josaList[index].updDate = josa.updDate
          josaList[index].guName = josa.guName
          josaList[index].guname = josa.guname
          josaList[index].step = josa.step
        } else {
          josaList.push(josa)
        }
      }
      state.JOSA.JOSA_LIST = josaList
    },
    MU_DETAIL_DATA(state: JoJosa, payload: any) {
      state.JOSA.JOSA_DETAIL = payload
    },
    MU_REPLACE_ADD_JOSA(state: JoJosa, payload: any) {
      const josaList = state.JOSA.JOSA_LIST
      if (!payload || payload.length === 0) {
        for (let i = 0; i < payload.length; i++) {
          const josa = payload[i]
          const index = josaList.findIndex(
            (item) => item.josaKey === josa.josaKey
          )
          if (index !== -1) {
            josaList[index] = josa
          } else {
            josaList.push(josa)
          }
        }
      }
      state.JOSA.JOSA_LIST = josaList
    },
    MU_JOSA_PART_UPDATE(state: JoJosa, payload: any) {
      const josaList = state.JOSA.JOSA_LIST
      let targetList = null
      let index = null
      const updateDataKey = payload.updateDataKey
      const updateDataValue = payload.updateDataValue
      const targetJosaKey = payload.targetKey
      if (!updateDataKey || !targetJosaKey) return
      targetList = josaList
      index = targetList?.findIndex((item) => item.josaKey === targetJosaKey)
      if (!targetList || targetList.length === 0) return
      if (index && index !== -1) {
        const target = targetList[index]
        target[updateDataKey] = updateDataValue
        josaList[index] = target
      }
      state.JOSA.JOSA_LIST = josaList
    },
    MU_JUSO_CODE_DATA(state: JoJosa, payload: any) {
      state.JOSA.jusoCodeList = payload
    },
    MU_DONG_DATA(state: JoJosa, payload: any) {
      const jusoData = state.JOSA.jusoCodeList
      let dongList = [] as any
      for (let i = 0; i < jusoData.length; i++) {
        if (payload === jusoData[i].guCode) {
          dongList = jusoData[i].dongList
        }
      }
      state.JOSA.DONG_LIST = dongList
    },
    MU_UPLOAD_PERCENT(state: any, payload: object) {
      state.JOSA.UPLOAD_PERCENT = payload
    }
  },
  actions: {
    AC_ONLY_ADD_HOUSE({ commit }: any, payload: object) {
      commit('MU_ONLY_ADD_HOUSE', payload)
    },
    AC_REPLACE_ADD_HOUSE({ commit }: any, payload: object) {
      commit('MU_REPLACE_ADD_HOUSE', payload)
    },
    async AC_ONLY_ADD_JOSA({ commit }: any, payload: object) {
      if (!payload) return
      const result = await postAxios(
        'https://www.josa1.kr:9443/josa1/jo.getJosaList',
        payload
      )
      console.log(result)
      let contsList = []
      if (result.data.result) {
        if (result.data.josa) {
          contsList = result.data.josa.content
          const keyList = []
          for (let i = 0; i < contsList.length; i++) {
            keyList.push(contsList[i].josaKey)
          }
          commit('MU_ONLY_ADD_JOSA', result.data.josa.content)
          result.data.josa.content = keyList
          return result
        }
      }
    },
    AC_REPLACE_ADD_JOSA({ commit }: any, payload: object) {
      commit('MU_REPLACE_ADD_JOSA', payload)
    },
    AC_JOSA_PART_UPDATE({ commit }: any, payload: object) {
      commit('MU_JOSA_PART_UPDATE', payload)
    },
    // async AC_DETAIL_DATA ({ commit } :any, payload: object) {
    //   if (!payload) return
    //   const result = await postAxios('https://www.josa1.kr:9443/josa1/jo.getJosaDetail', payload)
    //   let contsList = []
    //   if (result.data.result) {
    //     if (result.data.josa) {
    //       contsList = result.data.josa[0]
    //       console.log(contsList)
    //       const keyList = []
    //       keyList.push(contsList.josaKey)
    //       commit('MU_DETAIL_DATA', result.data.josa[0])
    //       result.data.josa[0] = keyList
    //       return result
    //     }
    //   }
    // },
    AC_CHANGE_JOSA_KEY_TO_DATA({ state }: any, payload: any) {
      const josaList = state.JOSA.JOSA_LIST
      const returnList = []
      if (!payload) return
      for (let p = 0; p < payload.length; p++) {
        const idx = josaList.findIndex(
          (item: any) => item.josaKey === payload[p]
        )
        if (idx !== -1) {
          const houseKey = josaList[idx].houseKey
          const index = state.JOSA.HOUSE_LIST.findIndex(
            (item: any) => item.houseKey === houseKey
          )
          if (index !== -1) {
            josaList[idx].HOUSE_INFO = state.JOSA.HOUSE_LIST[index]
          }

          returnList.push(josaList[idx])
        }
      }
      return returnList
    },
    AC_CHANGE_HOUSE_KEY_TO_DATA({ state }: any, payload: any) {
      const houseList = state.JOSA.HOUSE_LIST
      const returnList = []
      for (let p = 0; p < payload.length; p++) {
        const idx = houseList.findIndex(
          (item: any) => item.houseKey === payload[p]
        )
        if (idx !== -1) {
          const josaKey = houseList[idx].josaKey
          const index = state.JOSA.JOSA_LIST.findIndex(
            (item: any) => item.josaKey === josaKey
          )
          if (index !== -1) {
            houseList[idx].JOSA = state.JOSA.JOSA_LIST[index]
          }

          returnList.push(houseList[idx])
        }
      }
      return returnList
    },
    AC_JUSO_CODE_DATA({ commit }: any, payload: object) {
      commit('MU_JUSO_CODE_DATA', payload)
    },
    AC_DONG_DATA({ commit }: any, payload: object) {
      commit('MU_DONG_DATA', payload)
    },
    AC_UPLOAD_PERCENT({ commit }: any, payload: object) {
      commit('MU_UPLOAD_PERCENT', payload)
    },
    AC_DETAIL_DATA({ commit }: any, payload: object) {
      commit('MU_DETAIL_DATA', payload)
    }
  }
}

export default JO_JOSA
