import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'
import errorPage from '../views/JOErrorPage.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'totalMain',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/JOHousingMain.vue'),
    children: [
      {
        path: '/',
        name: 'main',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/JOMain.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          if (!user.joYn && !user.boYn && user.jiYn) next({ path: '/control' })
          // if (!user.joYn && user.boYn && !user.jiYn) next({ path: '/bogoMain' })
          // if (
          //   (!user.joYn && user.boYn && user.jiYn) ||
          //   (user.joYn && user.boYn && !user.jiYn)
          // ) {
          //   next({ path: '/bogoMain' })
          // }
          if (user.boYn) next({ path: '/bogoMain' })
          else next()
        }
      },
      {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOLogin.vue')
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOAbout.vue')
      },
      {
        path: '/mvList',
        name: 'mvList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOYoutubeList.vue')
      },
      {
        path: '/actorList',
        name: 'actorList',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOActorList.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/targetList',
        name: 'targetList',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOTargetList.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/report',
        name: 'report',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOReport.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/sahooReport',
        name: 'sahooReport',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOSahooReport.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/resultreport',
        name: 'resultreport',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOSiReport.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/sahooResultReport',
        name: 'sahooResultReport',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/JOSahooResultReport.vue'
          ),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/sahuList',
        name: 'sahuList',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOSahooList.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/sahuUserList',
        name: 'sahuUserList',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/JOSahooUserList.vue'
          ),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/polices',
        name: 'polices',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOPolicy.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/control',
        name: 'control',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOControlPage.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          if (!user.jiYn) next({ path: '/' })
          else next()
        }
      },
      {
        path: '/mapTest',
        name: 'mapTest',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/JOControlPageLF.vue'
          ),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/bogoMain',
        name: 'bogoMain',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/JOBogoGroupMain.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
          if (!user.boYn) next({ path: '/' })
          else next()
        }
      },
      {
        path: '/newMain',
        name: 'newMain',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JO2024NewMain.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          if (user.boYn && !user.jiYn && !user.joYn) {
            next({ path: '/bogoMain' })
          } else if (!user.boYn && user.jiYn && !user.joYn) {
            next({ path: '/control' })
          } else if (!user.boYn && !user.jiYn && user.joYn) {
            next({ path: '/' })
          } else next()
        }
      },
      {
        path: '/observe',
        name: 'observe',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/JOMapObserver.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['JO_USER/GE_USER']
          if (!user.userKey) next({ path: '/login' })
          else next()
        }
      },
      {
        path: '/errorPage',
        name: 'errorPage',
        component: errorPage
      },
      // {
      //   path: '/errorPage',
      //   name: 'errorPage',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/JOErrorPage.vue'),
      //   props: true
      // },
      { path: '/:catchAll(.*)', redirect: '/errorPage' }
    ]
  },
  {
    path: '/mv',
    name: 'mv',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [
      {
        path: '/mv/seoul01',
        name: '01',
        props: true,
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/JOMovePage.vue')
      }
    ]
  },
  // {
  //   path: '/main',
  //   name: 'newJosaMain',
  //   props: true,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/newViews/JONewMainRouter.vue'),
  //   children: [
  //     {
  //       path: '/createAsk',
  //       name: 'createAsk',
  //       props: true,
  //       component: () => import(/* webpackChunkName: "about" */ '@/newViews/pop/JOCustomCreateAskPop.vue')
  //     },
  //     {
  //       path: '/customJosa',
  //       name: 'customJosa',
  //       props: true,
  //       component: () => import(/* webpackChunkName: "about" */ '@/newViews/JOCustomJosaPop.vue')
  //     },
  //     {
  //       path: '/writeJosa',
  //       name: 'writeJosa',
  //       props: true,
  //       component: () => import(/* webpackChunkName: "about" */ '@/newViews/pop/JOCustomWriteJosaPop.vue')
  //     },
  //     {
  //       path: '/mainList',
  //       name: 'mainJosaList',
  //       props: true,
  //       component: () => import(/* webpackChunkName: "about" */ '@/newViews/JOCustomList.vue')
  //     },
  //     {
  //       path: '/main',
  //       name: 'newMain',
  //       props: true,
  //       component: () => import(/* webpackChunkName: "about" */ '@/newViews/JOMain.vue')
  //     }
  //   ]
  // },
  {
    path: '/home',
    name: 'homepage',
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/JOHomepageMain.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        props: true,
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/JOHomePageN.vue')
      },
      {
        path: '/home/info',
        name: 'info',
        props: true,
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/JOHomepageInfo.vue')
      }
    ]
  },
  {
    path: '/dev',
    name: 'dev',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/JODeveloperPage.vue')
  },
  {
    path: '/guide/error',
    name: 'errorGuide',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/JOErrorGuidePage.vue')
  },
  {
    path: '/ownerSign',
    name: 'ownerSign',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/components/sahoo/JOOwnerSignMain.vue'
      ),
    children: [
      {
        path: '/ownerSign',
        name: 'signLogin',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/sahoo/JOOwnerSIgnLogin.vue'
          )
      },
      {
        path: '/signAgreePage',
        name: 'signAgreePage',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/JOOwnerSIgnAgreePage.vue'
          )
      },
      {
        path: '/signAppliPage',
        name: 'signAppliPage',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/sahoo/JOOwnerSignAppliPage.vue'
          )
      },
      {
        path: '/signPPage',
        name: 'signPPage',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/sahoo/JOOwnerSignPPage.vue'
          )
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})
router.beforeResolve(function (to, from, next) {
  // to : 이동할 url
  // from : 현재 url
  // next : to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
  console.log('every single routing is pending')
  const popHistory = store.getters['JO_USER/GE_POP_HISTORY']
  console.log(popHistory)
  if (popHistory && popHistory.length > 0) {
    // alert(popHistory.length)
    store.dispatch(
      'JO_USER/AC_CHANGE_RM_POPID',
      popHistory[popHistory.length - 1].popId
    )
    // localStorage.setItem('closeHistoryStack', popHistory[popHistory.length - 1].popId)
    next(false)
    store.dispatch('JO_USER/AC_DEL_HISTORY', {
      popId: popHistory[popHistory.length - 1].popId
    })
    // this.$store.dispatch('JO_USER/AC_CHANGE_RM_POPID', '')
  } else {
    next()
  }
})

export default router
