
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pPopTitle: {},
    pJosaDetailPageYn: {},
    pClosePop: Function as any
  }
})
